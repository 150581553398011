import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import './IconGridItem.scss';

import { sanitizer, customPropTypes, useTransitionDelay } from '../../../util';
import { STAGGER } from '../../../util/motion-manager/constant';
import UniversalImage from '../../UniversalImage/UniversalImage';

function IconGridItem({ data: { icon, title, description }, index }) {
  const iconDelay = useTransitionDelay(STAGGER * (index + 0));
  const titleDelay = useTransitionDelay(STAGGER * (index + 1));
  const descriptionDelay = useTransitionDelay(STAGGER * (index + 2));

  return (
    <div className="IconGridItem">
      <div className="icon-container" style={iconDelay}>
        <UniversalImage
          className="icon"
          image={icon}
          imgStyle={{ objectFit: 'contain', objectPosition: 'center left' }}
        />
      </div>
      <h3 className="title" style={titleDelay}>
        {title}
      </h3>
      <p className="description" style={descriptionDelay}>
        {description}
      </p>
    </div>
  );
}

IconGridItem.propTypes = checkExtraProps({
  data: PropTypes.shape({
    icon: customPropTypes.image,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  index: PropTypes.number,
});

IconGridItem.defaultProps = {};

export default memo(IconGridItem);
