import React from 'react';
import Img from 'gatsby-image';

const UniversalImage = ({ image, aspectRatio, altText = '', imgStyle, ...others }) => {
  if (!image?.localFile) {
    return null;
  }

  if (image.localFile.extension === 'svg') {
    return <img src={image.localFile.publicURL} alt={image.altText || altText} {...others} />;
  }

  const imageProps = {
    ...(image.localFile.childImageSharp?.fluid || {}),
  };

  if (aspectRatio) {
    imageProps.aspectRatio = aspectRatio;
  }

  return <Img fluid={imageProps} alt={image.altText || altText} imgStyle={imgStyle} {...others} />;
};

export default UniversalImage;
